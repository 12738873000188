<template>
    <div style="display: flex; flex-direction: column; flex: 1">
        <div class="main-body-header">
            <div>{{ data.name }}</div>
   
            <div>
                 <el-button
                 v-if="data.openfrom=='formdesign'"
                    type="primary"
                    icon="el-icon-check"
                    size="mini"
                    @click="handleSelectModel"
                    >确认选择</el-button 
                >

                <el-button
                    type="primary"
                    icon="el-icon-edit"
                    size="mini"
                    @click="handleEditModel"
                    >编辑</el-button 
                >
            </div>

        </div>
        <div class="main-body-body">
            <div class="tabs">
                <div class="tabs-header">
                    <div
                        :class="
                            activeTab == 'preview'
                                ? 'tabs-header-item tabs-header-item-active'
                                : 'tabs-header-item'
                        "
                        @click="() => (activeTab = 'preview')"
                    >
                        数据预览
                    </div>
                    <div
                        :class="
                            activeTab == 'metas'
                                ? 'tabs-header-item tabs-header-item-active'
                                : 'tabs-header-item'
                        "
                        @click="() => (activeTab = 'metas')"
                    >
                        数据字段
                    </div>
                    <div
                        v-if="data.mode == '1'"
                        :class="
                            activeTab == 'updateData'
                                ? 'tabs-header-item tabs-header-item-active'
                                : 'tabs-header-item'
                        "
                        @click="() => (activeTab = 'updateData')"
                    >
                        数据更新
                    </div>
                    <div
                        :class="
                            activeTab == 'info'
                                ? 'tabs-header-item tabs-header-item-active'
                                : 'tabs-header-item'
                        "
                        @click="() => (activeTab = 'info')"
                    >
                        基本信息
                    </div>
                </div>
                <div class="tabs-body">
                    <div v-if="activeTab == 'preview'" class="tabs-panel">
                        <el-button
                            v-if="data.mode == '1' && data.type != '5' && data.extract_table == ''"
                            type="primary"
                            icon="el-icon-refresh"
                            size="small"
                            @click="handleUpdateData"
                            >更新</el-button
                        >
                        <!-- <vxe-grid
                            v-else
                            :max-height="panelContentHeight + 'px'"
                            show-overflow
                            ref="previewGrid"
                            border
                            :columns="previewColumn"
                            :data="previewList"
                        >
                        </vxe-grid> -->
                        <jf-table
                            v-else
                            :max-height="panelContentHeight"
                            :pager="false"
                            :operate="false"
                            :checkbox="false"
                            ref="previewGrid"
                            :columns="previewColumn"
                            :data="previewList"
                        >
                        </jf-table>
                    </div>
                    <div v-if="activeTab == 'metas'" class="tabs-panel">
                        <!-- <vxe-grid
                            :max-height="panelContentHeight + 'px'"
                            ref="metasGrid"
                            border
                            :columns="metasColumn"
                            :data="data.metas"
                        >
                        </vxe-grid> -->
                        <jf-table
                            :max-height="panelContentHeight"
                            :pager="false"
                            :operate="false"
                            :checkbox="false"
                            ref="metasGrid"
                            :columns="metasColumn"
                            :data="data.metas"
                        >
                        </jf-table>
                    </div>
                    <div v-if="activeTab == 'updateData'" class="tabs-panel">
                        <el-button v-if="data.mode == '1' && data.type != '5'"
                            type="primary"
                            icon="el-icon-refresh"
                            size="small"
                            @click="handleUpdateData"
                            >更新</el-button
                        >
                        <el-descriptions
                            v-if="updateTime"
                            :column="1"
                            style="margin-top: 10px"
                        >
                            <el-descriptions-item label="上次更新时间"
                                >{{ updateTime }}&nbsp;&nbsp;&nbsp;&nbsp;{{
                                    updateSeconds
                                }}</el-descriptions-item
                            >
                            <el-descriptions-item label="上次更新结果">{{
                                data.extract_result
                            }}</el-descriptions-item>
                        </el-descriptions>
                        <el-form v-if="data.mode == '1' && data.type != '5'" ref="form" :model="form" :rules="rules" label-width="100px" style="margin-top: 20px;">
                            <el-row>
                                <el-col :span="24">
                                    <el-form-item label="定时更新" prop="schedule">
                                        <el-switch
                                            v-model="form.schedule"
                                            active-value="1"
                                            inactive-value="0" @change="onScheduleChange()">
                                        </el-switch>
                                    </el-form-item>
                                </el-col>
                                <template v-if="form.schedule=='1'">
                                    <el-col :span="24">
                                        <el-form-item label="定时设置" prop="schedule_data.cron">
                                            <div style="width: 50%;">
                                                <dict-select
                                                    v-model="form.schedule_data.cron"
                                                    placeholder="请选择定时设置"
                                                    type="schedule_type"
                                                    filterable
                                                />
                                            </div>
                                        </el-form-item>
                                    </el-col>

                                    <el-col v-if="form.schedule_data.cron=='0'" :span="24">
                                        <el-form-item label="">
                                            <div style="display: flex; margin-top: 5px;">
                                                <div style="width: 100px; display: flex">
                                                    <el-select v-model="form.schedule_data.year" placeholder="" filterable >
                                                        <el-option v-for="(item, index) in years" :key="index" :label="item.label" :value="item.value"></el-option>
                                                    </el-select>
                                                    &nbsp;年&nbsp;
                                                </div>
                                                <div style="width: 100px; display: flex">
                                                    <el-select v-model="form.schedule_data.month" placeholder="" filterable >
                                                        <el-option v-for="(item, index) in months" :key="index" :label="item.label" :value="item.value"></el-option>
                                                    </el-select>
                                                    &nbsp;月&nbsp;
                                                </div>
                                                <div v-if="(!form.schedule_data.day || form.schedule_data.day == '*')" style="width: 100px; display: flex">
                                                    <el-select v-model="form.schedule_data.week" placeholder="" filterable >
                                                        <el-option v-for="(item, index) in weeks" :key="index" :label="item.label" :value="item.value"></el-option>
                                                    </el-select>
                                                    &nbsp;周&nbsp;
                                                </div>
                                                <div v-if="(!form.schedule_data.day || form.schedule_data.day == '*')" style="width: 140px; display: flex">
                                                    &nbsp;星期&nbsp;
                                                    <div style="width: 100px;">
                                                        <el-select v-model="form.schedule_data.weekday" placeholder="" filterable >
                                                            <el-option v-for="(item, index) in weekdays" :key="index" :label="item.label" :value="item.value"></el-option>
                                                        </el-select>
                                                    </div>
                                                </div>
                                                <div v-if="(!form.schedule_data.week || form.schedule_data.week == '?') && (!form.schedule_data.weekday || form.schedule_data.weekday == '?')" style="width: 100px; display: flex">
                                                    <el-select v-model="form.schedule_data.day" placeholder="" filterable >
                                                        <el-option v-for="(item, index) in days" :key="index" :label="item.label" :value="item.value"></el-option>
                                                    </el-select>
                                                    &nbsp;日&nbsp;
                                                </div>
                                                <div style="width: 100px; display: flex">
                                                    <el-select v-model="form.schedule_data.hour" placeholder="" filterable >
                                                        <el-option v-for="(item, index) in hours" :key="index" :label="item.label" :value="item.value"></el-option>
                                                    </el-select>
                                                    &nbsp;时&nbsp;
                                                </div>
                                                <div style="width: 100px; display: flex">
                                                    <el-select v-model="form.schedule_data.minute" placeholder="" filterable >
                                                        <el-option v-for="(item, index) in minutes" :key="index" :label="item.label" :value="item.value"></el-option>
                                                    </el-select>
                                                    &nbsp;分&nbsp;
                                                </div>
                                                <div style="width: 100px; display: flex">
                                                    <el-select v-model="form.schedule_data.second" placeholder="" filterable >
                                                        <el-option v-for="(item, index) in seconds" :key="index" :label="item.label" :value="item.value"></el-option>
                                                    </el-select>
                                                    &nbsp;秒&nbsp;
                                                </div>
                                            </div>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="更新开始时间" prop="schedule_begin_time">
                                            <datetime-select v-model="form.schedule_begin_time" type="datetime" placeholder="到这个时间开始更新，留空只判断定时设置" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item label="更新结束时间" prop="schedule_end_time">
                                            <datetime-select v-model="form.schedule_end_time" type="datetime" placeholder="到这个时间结束更新，留空只判断定时设置" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-form-item label="更新策略" prop="schedule_policy">
                                            <el-radio-group v-model="form.schedule_policy">
                                                <el-radio label="0">重复更新</el-radio>
                                                <el-radio label="1">更新1次</el-radio>
                                                <el-radio label="2">更新N次</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </el-col>
                                    <el-col v-if="form.schedule_policy=='2'" :span="12">
                                        <el-form-item label="更新次数" prop="schedule_count">
                                            <el-input-number v-model="form.schedule_count" :min="1" :step="1" placeholder="请输入更新次数" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="24">
                                        <el-button type="primary" :disabled="saveDisabled" @click="saveSchedule()">保 存</el-button>
                                    </el-col>
                                </template>
                            </el-row>
                        </el-form>
                    </div>
                    <div v-if="activeTab == 'info'" class="tabs-panel">
                        <el-descriptions :column="1">
                            <el-descriptions-item label="ID">{{
                                data.id
                            }}</el-descriptions-item>
                            <el-descriptions-item label="名称">{{
                                data.name
                            }}</el-descriptions-item>
                            <el-descriptions-item label="来源">{{
                                data.type == '4' ? ('API(' + data.url + ')') : (data.type == '5' ? ('EXCEL(' + (data.extObj && data.extObj.excelFileName || '') + ')') : ('数据库(' + data.database_name + ')'))
                            }}</el-descriptions-item>
                            <el-descriptions-item
                                v-if="data.extract_table != ''"
                                label="存储"
                                >数据库({{data.local_database_name }}.{{
                                    data.extract_table
                                }})</el-descriptions-item
                            >
                            <el-descriptions-item label="备注">{{
                                data.remark
                            }}</el-descriptions-item>
                        </el-descriptions>
                        <div v-if="data.type != '5'" style="font-size: 14px; color: #606266">
                            参数：
                        </div>
                        <div v-if="data.type != '5'" style="margin-top: 15px">
                            <!-- <vxe-grid
                                :max-height="
                                    panelContentHeight - 96 - 16 - 15 + 'px'
                                "
                                ref="paramsGrid"
                                border
                                :columns="paramsColumn"
                                :data="data.params"
                            >
                            </vxe-grid> -->
                            <jf-table
                                :max-height="panelContentHeight - 96 - 16 - 15"
                                :pager="false"
                                :operate="false"
                                :checkbox="false"
                                ref="paramsGrid"
                                :columns="paramsColumn"
                                :data="data.params"
                            >
                            </jf-table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    listModel,
    getModel,
    addModel,
    updateModel,
    updateModelSchedule,
    enableModelSchedule,
    delModel,
    previewModel,
    execModel,
    updateData,
} from "@/api/core/data/datasource/model";

export default {
    name: "model-detail",
    props: {
        data: {
            type: Object,
            default: () => {
                return {};
            },
        },
        height: {
            type: Number,
            default: () => {
                return 100;
            },
        },
    },
    data() {
        let schedule_data = undefined
        if (this.data.schedule_cron && this.data.schedule_cron.indexOf('{') == 0) {
            schedule_data = JSON.parse(this.data.schedule_cron)
        } else {
            schedule_data = {}
        }
        const form = {
            id: this.data.id,
            schedule: this.data.schedule,
            schedule_begin_time: this.data.schedule_begin_time || undefined,
            schedule_end_time: this.data.schedule_end_time || undefined,
            schedule_cron: this.data.schedule_cron,
            schedule_policy: this.data.schedule_policy,
            schedule_count: this.data.schedule_count,
            schedule_data: schedule_data
        }
        this.formstr = JSON.stringify(form)
        return {
            activeTab: "preview",
            metasColumn: [
                { field: "index", title: "序号", width: 100, fixed: "left" },
                { field: "name", title: "字段名" },
                { field: "type", title: "类型", formatter: this.typeFormat },
                { field: "label", title: "标签" },
                { field: "dict", title: "数据字典" },
                //{field: 'sort', title: '排序'},
            ],
            paramsColumn: [
                { field: "index", title: "序号", width: 100, fixed: "left" },
                { field: "name", title: "参数名" },
                { field: "default_value", title: "默认值" },
                { field: "remark", title: "备注" },
                //{field: 'sort', title: '排序'},
            ],
            previewColumn: [],
            previewList: [],
            formstr: JSON.stringify(form),
            form: form,
            saveDisabled: true,
			rules: {
                "schedule_data.cron": [{ required: true, message: "定时设置不能为空", trigger: "blur" }],
                schedule_policy: [{ required: true, message: "请选择更新策略", trigger: "blur" }],
                schedule_count: [{ required: true, message: "更新次数不能为空", trigger: "blur" }],
			},
            years: [],
            months: [],
            weeks: [],
            weekdays: [],
            days: [],
            hours: [],
            minutes: [],
            seconds: []
        };
    },
    created() {
        const years = [{
            label: '每',
            value: '?'
        }]
        const year = new Date().getFullYear();
        for (let i = 0; i < 5; i++) {
            const y = year + i;
            years.push({
                label: '' + y,
                value: '' + y
            })
        }
        this.years = years

        const months = [{
            label: '每',
            value: '*'
        }]
        for (let i = 1; i <= 12; i++) {
            months.push({
                label: '' + i,
                value: '' + i
            })
        }
        this.months = months

        const days = [{
            label: '每',
            value: '*'
        }]
        for (let i = 1; i <= 31; i++) {
            days.push({
                label: '' + i,
                value: '' + i
            })
        }
        days.push({
            label: '最后1',
            value: 'L'
        })
        this.days = days

        const weeks = [{
            label: '不限',
            value: '?'
        }]
        for (let i = 1; i <= 4; i++) {
            weeks.push({
                label: '第' + i,
                value: '' + i
            })
        }
        weeks.push({
            label: '最后1',
            value: 'L'
        })
        this.weeks = weeks

        const weekdays = [{
            label: '不限',
            value: '?'
        }, 
        {
            label: '一',
            value: '1'
        }, 
        {
            label: '二',
            value: '2'
        }, 
        {
            label: '三',
            value: '3'
        }, 
        {
            label: '四',
            value: '4'
        }, 
        {
            label: '五',
            value: '5'
        }, 
        {
            label: '六',
            value: '6'
        }, 
        {
            label: '日',
            value: '0'
        }]
        this.weekdays = weekdays

        const hours = [{
            label: '每',
            value: '*'
        }]
        for (let i = 0; i <= 23; i++) {
            hours.push({
                label: '' + i,
                value: '' + i
            })
        }
        this.hours = hours

        const minutes = [{
            label: '每',
            value: '*'
        }]
        for (let i = 0; i <= 59; i++) {
            minutes.push({
                label: '' + i,
                value: '' + i
            })
        }
        this.minutes = minutes

        const seconds = [{
            label: '每',
            value: '*'
        }]
        for (let i = 0; i <= 59; i++) {
            seconds.push({
                label: '' + i,
                value: '' + i
            })
        }
        this.seconds = seconds

        this.refresh();
    },
    methods: {
        handleEditModel() {
            this.$emit("edit-model");
        },
        handleSelectModel(){
            this.$emit("handleSelectModel",this.data);
        },

        typeFormat({ row }) {
            if (row.type == "number") {
                return "数值";
            } else if (row.type == "object") {
                return "对象";
            } else {
                return "文本";
            }
        },

        refresh() {
            this.previewColumn = [];
            this.previewList = [];

            const loading = this.$loading({
                lock: true,
                //text: '正在加载数据',
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            getModel(this.data.id)
                .then((response) => {
                    if (response.code == 0) {
                        for (let k in response.data) {
                            this.data[k] = response.data[k];
                        }

                        this.$emit("refresh-model", this.data);

                        for (let i = 0; i < this.data.params.length; i++) {
                            this.data.params[i].index = i + 1;
                        }
                        const previewColumn = [];
                        for (let i = 0; i < this.data.metas.length; i++) {
                            const m = this.data.metas[i];
                            m.index = i + 1;
                            previewColumn.push({
                                field: m.name,
                                title: m.label||m.name,
                                minWidth: 120,
                            });
                        }
                        this.previewColumn = previewColumn;
                        const maxCount = 1000;
                        execModel(
                            this.data.id,
                            JSON.stringify({ __max_count__: maxCount })
                        )
                            .then((response) => {
                                loading.close();
                                if (response.code == 0) {
                                    if (
                                        previewColumn.length == 0 &&
                                        response.data.length > 0
                                    ) {
                                        const previewColumn = [];
                                        const d = response.data[0];
                                        for (let k in d) {
                                            previewColumn.push({
                                                field: k,
                                                title: k,
                                                minWidth: 120,
                                            });
                                        }
                                        this.previewColumn = previewColumn;
                                    }
                                    this.previewList = response.data;
                                }
                            })
                            .catch((err) => {
                                loading.close();
                            });
                    }
                })
                .catch((err) => {
                    loading.close();
                });
        },

        handleUpdateData() {
            const that = this;
            let loading = null;
            const id = this.data.id;
            this.$confirm("确定更新数据吗？", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                    loading = that.$loading({
                        lock: true,
                        //text: '正在加载数据',
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.1)",
                    });
                    return updateData(id, "{}");
                })
                .then(() => {
                    if (loading) {
                        loading.close();
                    }
                    this.msgSuccess("更新成功");
                    this.refresh();
                })
                .catch((err) => {
                    if (loading) {
                        loading.close();
                    }
                    console.log(err);
                });
        },

        onScheduleChange() {
            let text = this.form.schedule === '1' ? "启用" : "停用";
            this.$confirm(
                '确认要' + text + '定时更新吗?',
                "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }
            ).then(() => {
                return enableModelSchedule({id: this.data.id, schedule: this.form.schedule})
            }).then(() => {
                this.msgSuccess(text + "成功");
            }).catch(() => {
                this.form.schedule = this.form.schedule === "0" ? "1" : "0";
            });
        },
        saveSchedule() {
            this.$refs["form"].validate((valid) => {
				if (valid) {
                    if (this.form.schedule == '1') {
                        if (!this.form.schedule_data.cron) {
                            this.msgError('定时设置不能为空')
                            return
                        }
                        if (this.form.schedule_data.cron == '0') {
                            if (!this.form.schedule_data.year) {
                                this.msgError('请选择年')
                                return
                            }
                            if (!this.form.schedule_data.month) {
                                this.msgError('请选择月')
                                return
                            }
                            if (!this.form.schedule_data.day) {
                                if (!this.form.schedule_data.week) {
                                    this.msgError('请选择周')
                                    return
                                }
                                if (!this.form.schedule_data.weekday) {
                                    this.msgError('请选择星期')
                                    return
                                }
                            }
                            if (this.form.schedule_data.week && this.form.schedule_data.week != '?') {
                                if (!this.form.schedule_data.weekday) {
                                    this.msgError('请选择星期')
                                    return
                                }
                                if (this.form.schedule_data.weekday == '?') {
                                    this.msgError('只能选择星期一到星期日')
                                    return
                                }
                            }
                            if ((!this.form.schedule_data.week || this.form.schedule_data.week == '?') && (!this.form.schedule_data.weekday || this.form.schedule_data.weekday == '?')) {
                                if (!this.form.schedule_data.day) {
                                    this.msgError('请选择日')
                                    return
                                }
                            }
                            if (!this.form.schedule_data.hour) {
                                this.msgError('请选择时')
                                return
                            }
                            if (!this.form.schedule_data.minute) {
                                this.msgError('请选择分')
                                return
                            }
                            if (!this.form.schedule_data.second) {
                                this.msgError('请选择秒')
                                return
                            }

                            const sd = this.form.schedule_data
                            let cron = sd.second + ' ' + sd.minute + ' ' + sd.hour + ' '
                            if ((sd.week && sd.week != '?') || (sd.weekday && sd.weekday != '?')) {
                                cron += '?'
                            } else {
                                cron += sd.day
                            }
                            cron += ' ' + sd.month
                            if (sd.weekday && sd.weekday != '?') {
                                cron += ' ' + sd.weekday
                                if (sd.week && sd.week != '?') {
                                    if (sd.week != 'L') {
                                        cron += '#'
                                    }
                                    cron += sd.week
                                }
                            } else {
                                cron += ' ?'
                            }
                            if (sd.year && sd.year != '?') {
                                cron += ' ' + sd.year
                            }
                            sd.cronc = cron
                        }
                        if (this.form.schedule_count === undefined) {
                            this.msgError('抽取次数不能为空')
                            return
                        }
                    }
                    this.form.schedule_cron = JSON.stringify(this.form.schedule_data)
					const form = JSON.parse(JSON.stringify(this.form))
					
                    const loading = this.$loading({
                        text: '正在加载...'
                    })
                    updateModelSchedule(form).then(response => {
                        loading.close()
                        if (response.code === 0) {
                            this.saveDisabled = true
                            this.msgSuccess("保存成功");
                        }
                    }).catch(e => {
                        loading.close()
                    })
				}
			});
        }
    },
    watch: {
        data(val) {
            let schedule_data = undefined
            if (this.data.schedule_cron && this.data.schedule_cron.indexOf('{') == 0) {
                schedule_data = JSON.parse(this.data.schedule_cron)
            } else {
                schedule_data = {}
            }
            const form = {
                id: this.data.id,
                schedule: this.data.schedule,
                schedule_begin_time: this.data.schedule_begin_time || undefined,
                schedule_end_time: this.data.schedule_end_time || undefined,
                schedule_cron: this.data.schedule_cron,
                schedule_policy: this.data.schedule_policy,
                schedule_count: this.data.schedule_count,
                schedule_data: schedule_data
            }
            this.formstr = JSON.stringify(form)
            this.form = form
            this.saveDisabled = true
            this.refresh();
        },
        form: {
            handler(n, o) {
                this.saveDisabled = this.formstr == JSON.stringify(this.form)
            },
            deep: true
        }
    },
    computed: {
        panelContentHeight() {
            return this.height - 80 - 30;
        },
        updateTime() {
            if (!this.data.extract_start_time) {
                return "";
            }
            return this.XEUtils.toDateString(
                new Date(this.data.extract_start_time * 1000),
                "yyyy-MM-dd HH:mm:ss"
            );
        },
        updateSeconds() {
            if (this.data.extract_end_time >= this.data.extract_start_time) {
                return (
                    "耗时" +
                    (this.data.extract_end_time -
                        this.data.extract_start_time) +
                    "秒"
                );
            } else {
                return "";
            }
        }
    },
};
</script>

<style scoped>
.main-body-header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px;
    font-weight: bold;
}
.main-body-body {
    flex: 1;
}
.tabs {
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}
.tabs-header {
    height: 40px;
    background-color: #f2f4f7;
    display: flex;
}
.tabs-header-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
    font-size: 13px;
    color: #333;
    font-weight: bold;
    cursor: pointer;
}
.tabs-header-item-active {
    background-color: #fff;
    border-top: solid 2px #1890ff;
    border-bottom: solid 2px #fff;
    color: #1890ff;
}
.tabs-header-item:hover {
    color: #1890ff;
}
.tabs-body {
    flex: 1;
}
.tabs-panel {
    height: 100%;
    padding: 15px;
}
</style>