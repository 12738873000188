<template>
    <div class="app-container">
        <el-dialog
            :title="title"
            :visible.sync="open"
            width="1080px"
            :close-on-click-modal="false"
            append-to-body
        >
            <el-tabs v-model="activeTab" tab-position="left">
                <el-tab-pane label="基本配置" name="basic" :lazy="true">
                    <el-form
                        ref="form"
                        :model="form"
                        :rules="rules"
                        label-width="80px"
                    >
                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="名称" prop="name">
                                    <el-input
                                        v-model="form.name"
                                        placeholder="请输入名称"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="数据库" prop="database_id">
                                    <treeselect
                                        v-model="form.database_id"
                                        :disable-branch-nodes="true"
                                        :options="databaseTree"
                                        placeholder="请选择数据库连接"
                                        :normalizer="normalizerDatabaseNode"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="备注" prop="remark">
                                    <el-input
                                        v-model="form.remark"
                                        type="textarea"
                                        placeholder="请输入备注"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <el-form-item label="sql语句" prop="sql">
                                    <!--<el-input v-model="form.sql" type="textarea" rows="8" placeholder="请输入sql语句" />-->
                                    <div style="border: solid 1px #dcdfe6;">
                                         <monaco-editor v-model="form.sql"
                                          :read-only="false"
                                          language="sql"
                                          :options="{fontSize: 14, padding: {top: 5, bottom: 5}, minimap: {enabled: false}}"
                                          theme="default"
                                          height="200"
                                          width="811"
                                        /> 
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <span style="color: #999">参数示例：select * from user where name=#{参数名}</span>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="24">
                                <!-- <vxe-grid
                                    ref="paramsGrid"
                                    row-key
                                    max-height="290"
                                    border
                                    class="sortable-row-demo"
                                    :columns="paramsColumn"
                                    :data="form.params"
                                    :edit-config="{
                                        trigger: 'click',
                                        mode: 'cell',
                                    }"
                                    :toolbar-config="tableToolbar1"
                                >
                                    <template #toolbar_buttons>
                                        <el-button
                                            type="primary"
                                            icon="el-icon-plus"
                                            size="mini"
                                            @click="addParam"
                                            >添加参数</el-button
                                        >
                                    </template>
                                    <template #paramsdefaultopr="{ row }">
                                        <el-button
                                            size="mini"
                                            type="text"
                                            icon="el-icon-delete"
                                            @click="handleDeleteParam(row)"
                                            >删除
                                        </el-button>
                                    </template>
                                </vxe-grid> -->
                                <jf-table
                                    ref="paramsGrid"
                                    row-id="row"
                                    :max-height="290"
                                    :checkbox="false"
                                    class="sortable-row-demo"
                                    :columns="paramsColumn"
                                    :defaultopr-width="80"
                                    :columns-btn="false"
                                    
                                    :filter-btn="false"
                                    :sort-btn="false"
                                    :pager="false"
                                    :data="form.params"
                                    :edit-config="{
                                        trigger: 'click',
                                        mode: 'cell',
                                    }"
                                    :toolbar-config="tableToolbar1"
                                >
                                    <template slot="toolbar_btn_left">
                                        <el-button
                                            type="primary"
                                            icon="el-icon-plus"
                                            size="mini"
                                            @click="addParam"
                                            >添加参数</el-button
                                        >
                                    </template>
                                    <template
                                        slot="toolbar_btn_row"
                                        slot-scope="{ row }"
                                    >
                                        <el-button
                                            size="mini"
                                            type="text"
                                            icon="el-icon-delete"
                                            @click="handleDeleteParam(row)"
                                            >删除
                                        </el-button>
                                    </template>
                                    <template
                                        slot="default_sort"
                                        slot-scope="{ row }"
                                    >
                                        <span class="drag-btn">
                                            <i class="vxe-icon--menu"></i>
                                        </span>
                                    </template>
                                </jf-table>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-tab-pane>
                <el-tab-pane label="数据列配置" name="meta" :lazy="true">
                    <!-- <vxe-grid
                        v-if="activeTab == 'meta'"
                        ref="metasGrid"
                        row-key
                        max-height="400"
                        border
                        class="sortable-row-demo"
                        :columns="metasColumn"
                        :data="form.metas"
                        :edit-config="{ trigger: 'click', mode: 'cell' }"
                        :toolbar-config="tableToolbar1"
                    >
                        <template #toolbar_buttons>
                            <el-button
                                type="primary"
                                icon="el-icon-refresh"
                                size="mini"
                                @click="analysisMeta"
                                >解析</el-button
                            >
                        </template>
                        <template #display_type="{ row }">
                            <el-select
                                v-model="row.display_type"
                                placeholder="请选择"
                            >
                                <el-option label="文本" value=""></el-option>
                                <el-option
                                    label="图片"
                                    value="image"
                                ></el-option>
                                <el-option
                                    label="二维码"
                                    value="qrcode"
                                ></el-option>
                                <el-option
                                    label="链接"
                                    value="link"
                                ></el-option>
                            </el-select>
                        </template>
                        <template #parse_type="{ row }">
                            <el-select
                                v-model="row.parse_type"
                                placeholder="请选择"
                            >
                                <el-option label="无" value=""></el-option>
                                <el-option
                                    label="自循环"
                                    value="self_cycle"
                                ></el-option>
                                <el-option
                                    label="自循环（逗号分割）"
                                    value="self_cycle_,"
                                ></el-option>
                                <el-option
                                    label="自循环（分号分割）"
                                    value="self_cycle_;"
                                ></el-option>
                                <el-option
                                    label="自循环（json）"
                                    value="self_cycle_json"
                                ></el-option>
                            </el-select>
                        </template>
                        <template #metasdefaultopr="{ row }">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click="handleDeleteMeta(row)"
                                >删除
                            </el-button>
                        </template>
                    </vxe-grid> -->
                    <jf-table
                        v-if="activeTab == 'meta'"
                        ref="metasGrid"
                        row-id="row"
                        :max-height="400"
                        class="sortable-row-demo"
                        :columns="metasColumn"
                        :data="form.metas"
                        :edit-config="{ trigger: 'click', mode: 'cell' }"
                        :toolbar-config="tableToolbar1"
                        :defaultopr-width="80"
                        :checkbox="false"
                        :columns-btn="false"
                        :filter-btn="false"
                        :sort-btn="false"
                        :pager="false"
                    >
                        <template slot="toolbar_btn_left">
                            <el-button
                                type="primary"
                                icon="el-icon-refresh"
                                size="mini"
                                @click="analysisMeta"
                                >解析</el-button
                            >
                        </template>
                        <template slot="toolbar_btn_row" slot-scope="{ row }">
                            <el-button
                                size="mini"
                                type="text"
                                icon="el-icon-delete"
                                @click="handleDeleteMeta(row)"
                                >删除
                            </el-button>
                        </template>
                        <template slot="parse_type" slot-scope="{ row }">
                            <el-select
                                v-model="row.parse_type"
                                placeholder="请选择"
                            >
                                <el-option label="无" value=""></el-option>
                                <el-option
                                    label="自循环"
                                    value="self_cycle"
                                ></el-option>
                                <el-option
                                    label="自循环（逗号分割）"
                                    value="self_cycle_,"
                                ></el-option>
                                <el-option
                                    label="自循环（分号分割）"
                                    value="self_cycle_;"
                                ></el-option>
                                <el-option
                                    label="自循环（json）"
                                    value="self_cycle_json"
                                ></el-option>
                            </el-select>
                        </template>
                        <template slot="display_type" slot-scope="{ row }">
                            <el-select
                                v-model="row.display_type"
                                placeholder="请选择"
                            >
                                <el-option label="文本" value=""></el-option>
                                <el-option
                                    label="图片"
                                    value="image"
                                ></el-option>
                                <el-option
                                    label="二维码"
                                    value="qrcode"
                                ></el-option>
                                <el-option
                                    label="链接"
                                    value="link"
                                ></el-option>
                            </el-select>
                        </template>
                        <template slot="type" slot-scope="{ row }">
                            {{ typeFormat({ row }) }}
                        </template>
                        <template slot="default_sort" slot-scope="{ row }">
                            <span class="drag-btn">
                                <i class="vxe-icon--menu"></i>
                            </span>
                        </template>
                    </jf-table>
                </el-tab-pane>
            </el-tabs>
            <div slot="footer" class="dialog-footer">
                <el-button style="float: left" @click="previewData"
                    >预览数据</el-button
                >
                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="cancel">取 消</el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="预览数据"
            :visible.sync="openPreview"
            width="960px"
            append-to-body
        >
            <!-- <vxe-grid
                show-overflow
                ref="previewGrid"
                max-height="400"
                border
                :columns="previewColumn"
                :data="previewList"
                :toolbar-config="tableToolbar2"
            >
            </vxe-grid> -->
            <jf-table
                ref="previewGrid"
                :max-height="400"
                :checkbox="false"
                :columns="previewColumn"
                :data="previewList"
                :toolbar-config="tableToolbar2"
                :columns-btn="false"
                :filter-btn="false"
                :sort-btn="false"
                :pager="false"
                :operate="false"
               
            >
            </jf-table>

            <div slot="footer" class="dialog-footer">
                <el-button @click="closePreview">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    getModel,
    addModel,
    updateModel,
    previewModel,
} from "@/api/core/data/datasource/model";
import { getDatabaseTree } from "@/api/core/data/datasource/database";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Loading } from "element-ui";
import Sortable from "sortablejs";
import MonacoEditor from "@/utils/monaco-editor";
// MonacoEditor
export default {
    name: "add-model",
    components: { Treeselect, MonacoEditor },
    data() {
        return {
            tableToolbar1: {
                perfect: false,
                zoom: true,
                custom: false,
                slots: {
                    buttons: "toolbar_buttons",
                },
            },
            tableToolbar2: {
                perfect: false,
                zoom: true,
                custom: false,
            },
            metasColumn: [
                { field: "index", title: "序号", width: 50, align: "center" },
                { field: "name", title: "字段名" },
                {
                    field: "type",
                    title: "类型",
                    width: 60,
                    // formatter: this.typeFormat,
                    slots: { default: "type" },
                },
                {
                    field: "display_type",
                    title: "展示方式",
                    width: 130,
                    slots: { default: "display_type" },
                },
                {
                    field: "parse_type",
                    title: "解析方式",
                    width: 180,
                    slots: { default: "parse_type" },
                },
                {
                    field: "label",
                    title: "标签",
                    editRender: {
                        name: "input",
                        attrs: { type: "text", placeholder: "请输入标签" },
                    },
                },
                {
                    field: "dict",
                    title: "数据字典",
                    editRender: {
                        name: "input",
                        attrs: { type: "text", placeholder: "请输入数据字典" },
                    },
                },
                //{field: 'sort', title: '排序', editRender: {name: 'input', attrs: {type: 'number', placeholder: '请输入排序'}}},
                {
                    field: "sort",
                    title: "排序",
                    width: 60,
                    align: "center",
                    slots: {
                        default: "default_sort",
                        // () => {
                        //     return [
                        //         <span class="drag-btn">
                        //             <i class="vxe-icon--menu"></i>
                        //         </span>,
                        //     ];
                        // },
                    },
                },
                //{type: 'checkbox', width: 60 }
                // {
                //     field: "",
                //     title: "操作",
                //     resizable: false,
                //     width: 80,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "metasdefaultopr" },
                // },
            ],
            paramsColumn: [
                { field: "index", title: "序号", width: 80, align: "center" },
                {
                    field: "name",
                    title: "参数名",
                    editRender: {
                        name: "input",
                        attrs: { type: "text", placeholder: "请输入参数名" },
                    },
                },
                //{field: 'type', title: '类型', editRender: {name: 'select', options: [{label: '', value: ''}, {label: 'string', value: 'string'}, {label: 'number', value: 'number'}]} },
                //{field: 'label', title: '标签', editRender: {name: 'input', attrs: {type: 'text', placeholder: '请输入标签'}}},
                {
                    field: "default_value",
                    title: "默认值",
                    editRender: {
                        name: "input",
                        attrs: { type: "text", placeholder: "请输入默认值" },
                    },
                },
                {
                    field: "remark",
                    title: "备注",
                    editRender: {
                        name: "input",
                        attrs: { type: "text", placeholder: "请输入备注" },
                    },
                },
                //{field: 'sort', title: '排序', editRender: {name: 'input', attrs: {type: 'number', placeholder: '请输入排序'}}},
                {
                    field: "sort",
                    title: "排序",
                    width: 60,
                    align: "center",
                    // slots: {
                    //     default: () => {
                    //         return [
                    //             <span class="drag-btn">
                    //                 <i class="vxe-icon--menu"></i>
                    //             </span>,
                    //         ];
                    //     },
                    // },
                    slots: {
                        default: "default_sort",
                        // () => {
                        //     return [
                        //         <span class="drag-btn">
                        //             <i class="vxe-icon--menu"></i>
                        //         </span>,
                        //     ];
                        // },
                    },
                },
                // {
                //     field: "",
                //     title: "操作",
                //     resizable: false,
                //     width: 80,
                //     fixed: "right",
                //     align: "center",
                //     slots: { default: "paramsdefaultopr" },
                // },
            ],
            previewColumn: [],
            previewList: [],
            activeTab: "basic",
            loading: true,
            title: "",
            open: false,
            openPreview: false,
            databaseTree: [],
            form: {
                metas: [],
                params: [],
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "名称不能为空",
                        trigger: "blur",
                    },
                ],
                database_id: [
                    {
                        required: true,
                        message: "请选择数据库连接",
                        trigger: "blur",
                    },
                ],
                sql: [
                    {
                        required: true,
                        message: "sql语句不能为空",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        getDatabaseTree().then((response) => {
            if (response.code == 0) {
                this.databaseTree = response.data;
            }
        });
    },
    methods: {
        rowDropParams() {
            this.$nextTick(() => {
                if (this.sortableParams) {
                    return;
                }
                const paramsGrid = this.$refs.paramsGrid;
                const xTable = paramsGrid.$refs.xGrid;
                console.log(xTable, "xTable", this.$refs.paramsGrid);
                this.sortableParams = Sortable.create(
                    xTable.$el.querySelector(
                        ".body--wrapper>.vxe-table--body tbody"
                    ),
                    {
                        handle: ".drag-btn",
                        onEnd: ({ newIndex, oldIndex }) => {
                            const currRow = this.form.params.splice(
                                oldIndex,
                                1
                            )[0];
                            this.form.params.splice(newIndex, 0, currRow);
                            for (let i = 0; i < this.form.params.length; i++) {
                                this.form.params[i].sort = i + 1;
                            }
                        },
                    }
                );
            });
        },
        rowDropMetas() {
            this.$nextTick(() => {
                if (this.sortableMetas) {
                    return;
                }
                const xTable = this.$refs.metasGrid;
                this.sortableMetas = Sortable.create(
                    xTable.$el.querySelector(
                        ".body--wrapper>.vxe-table--body tbody"
                    ),
                    {
                        handle: ".drag-btn",
                        onEnd: ({ newIndex, oldIndex }) => {
                            const currRow = this.form.metas.splice(
                                oldIndex,
                                1
                            )[0];
                            this.form.metas.splice(newIndex, 0, currRow);
                            for (let i = 0; i < this.form.metas.length; i++) {
                                this.form.metas[i].sort = i + 1;
                            }
                        },
                    }
                );
            });
        },
        normalizerDatabaseNode(e) {
            e.label = e.name;
            return e;
        },
        cancel() {
            this.previewColumn = [];
            this.previewList = [];
            this.open = false;
            this.reset();
        },
        reset() {
            this.form = {
                id: undefined,
                pid: 0,
                type: "3",
                mode: "0",
                name: "",
                database_id: undefined,
                sql: "",
                ext: "",
                remark: undefined,
                metas: [],
                params: [],
            };
            this.resetForm("form");
        },
        add(mode, pid) {
            this.reset();
            this.form.mode = mode;
            this.form.pid = pid;
            this.activeTab = "basic";
            this.open = true;
            this.title =
                "添加SQL数据集（" + (mode == "0" ? "实时" : "抽取") + "）";
        },
        update(id) {
            this.reset();
            getModel(id).then((response) => {
                //this.form = response.data;
                let data = response.data;
                if (data) {
                    this._sql_ = data.sql;
                    for (let key in data) {
                        this.form[key] = data[key];
                    }

                    for (let i = 0; i < this.form.metas.length; i++) {
                        const m = this.form.metas[i];
                        m.index = i + 1;
                        m.sort = i + 1;
                    }

                    for (let i = 0; i < this.form.params.length; i++) {
                        const p = this.form.params[i];
                        p.index = i + 1;
                        p.sort = i + 1;
                    }

                    this.activeTab = "basic";
                    this.open = true;
                    this.title =
                        "修改SQL数据集（" +
                        (data.mode == "0" ? "实时" : "抽取") +
                        "）";
                }
            });
        },
        submitForm: function () {
            //console.log(this.form)
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    /*if (!this.form.metas || this.form.metas.length == 0 || this._sql_ != this.form.sql) {
            this.analysisMeta().then(() => {
              this.doSubmitForm()
            })
          } else {*/
                    this.doSubmitForm();
                    //}
                }
            });
        },
        doSubmitForm: function () {
            if (this.form.id != undefined) {
                updateModel(this.form).then((response) => {
                    if (response.code === 0) {
                        this.msgSuccess("修改成功");
                        this.previewColumn = [];
                        this.previewList = [];
                        this.open = false;
                        this.$emit("change", response.data);
                    } else {
                        this.msgError(response.msg);
                    }
                });
            } else {
                addModel(this.form).then((response) => {
                    if (response.code === 0) {
                        this.msgSuccess("新增成功");
                        this.previewColumn = [];
                        this.previewList = [];
                        this.open = false;
                        this.$emit("change", response.data);
                    } else {
                        this.msgError(response.msg);
                    }
                });
            }
        },
        typeFormat({ row }) {
            if (row.type == "number") {
                return "数值";
            } else {
                return "文本";
            }
        },
        previewData() {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    this.loadingInstance = Loading.service({
                        text: "拼命加载中",
                        background: "rgba(0,0,0,0.2)",
                        spinner: "el-icon-loading",
                    });
                    const maxCount = 1000;
                    const pform = JSON.parse(JSON.stringify(this.form));
                    const sql = pform.sql.trim().toLowerCase().replace(/\n/g, " ");
                    if (sql.indexOf('call ') != 0) {
                        const sps = sql.split(' limit ')
                        if (sps.length <= 1 || !/^[0-9,\s]+$/.test(sps[sps.length - 1])) {
                            pform.sql = pform.sql + " limit " + maxCount;
                        } else {
                            /*const cindex = pform.sql.indexOf(",", limitIndex)
                if (cindex < 0) {
                pform.sql = pform.sql.substr(0, limitIndex) + " limit " + maxCount
                } else {
                pform.sql = pform.sql.substr(0, cindex + 1) + maxCount
                }*/
                        }
                    }
                    previewModel(pform)
                        .then((response) => {
                            this.loadingInstance.close();
                            if (response.code === 0) {
                                if (response.data && response.data.length > 0) {
                                    const previewColumn = [];
                                    for (
                                        let i = 0;
                                        i < this.form.metas.length;
                                        i++
                                    ) {
                                        const m = this.form.metas[i];
                                        m.index = i + 1;
                                        m.sort = i + 1;
                                        previewColumn.push({
                                            field: m.name,
                                            title: m.name,
                                            minWidth: 120,
                                        });
                                    }
                                    if (previewColumn.length == 0) {
                                        const d = response.data[0];
                                        for (let k in d) {
                                            previewColumn.push({
                                                field: k,
                                                title: k,
                                                minWidth: 150,
                                            });
                                        }
                                    }
                                    this.previewColumn = previewColumn;
                                    this.previewList = response.data;
                                    this.openPreview = true;
                                } else {
                                    this.previewColumn = [];
                                    this.previewList = [];
                                    this.openPreview = true;
                                }
                            } else {
                                this.msgError(response.msg);
                            }
                        })
                        .catch((e) => {
                            this.loadingInstance.close();
                        });
                } else {
                    this.activeTab = "basic";
                }
            });
        },
        analysisMeta() {
            return new Promise((resolve, reject) => {
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        this.loadingInstance = Loading.service({
                            text: "拼命加载中",
                            background: "rgba(0,0,0,0.2)",
                            spinner: "el-icon-loading",
                        });
                        const pform = JSON.parse(JSON.stringify(this.form));
                        const sql = pform.sql.trim().toLowerCase().replace(/\n/g, " ");
                        if (sql.indexOf('call ') != 0) {
                            const limitIndex = sql.indexOf(" limit ");
                            if (limitIndex < 0) {
                                pform.sql = pform.sql + " limit 1";
                            } else {
                                // TODO: 如果limit在子查询里面，这种方式会有问题
                                //pform.sql = pform.sql.substr(0, limitIndex) + " limit 1"
                            }
                        }
                        previewModel(pform)
                            .then((response) => {
                                this.loadingInstance.close();
                                if (response.code === 0) {
                                    if (
                                        response.data &&
                                        response.data.length > 0
                                    ) {
                                        const metas = [];
                                        let index = 1;
                                        const d = response.data[0];
                                        for (let k in d) {
                                            const v = d[k];
                                            let oldMeta = null;
                                            for (
                                                let i = 0;
                                                i < this.form.metas.length;
                                                i++
                                            ) {
                                                const m = this.form.metas[i];
                                                if (m.name == k) {
                                                    oldMeta = m;
                                                    break;
                                                }
                                            }
                                            metas.push({
                                                index: index,
                                                name: k,
                                                type: typeof v,
                                                label:
                                                    (oldMeta &&
                                                        oldMeta.label) ||
                                                    "",
                                                dict:
                                                    (oldMeta && oldMeta.dict) ||
                                                    "",
                                                sort: index, //oldMeta && oldMeta.sort || 0
                                            });
                                            index++;
                                        }
                                        this.form.metas = metas;
                                    } else {
                                        this.form.metas = [];
                                    }
                                    resolve();
                                } else {
                                    this.msgError(response.msg);
                                    reject();
                                }
                            })
                            .catch((e) => {
                                this.loadingInstance.close();
                                reject();
                            });
                    } else {
                        this.activeTab = "basic";
                        reject();
                    }
                });
            });
        },
        closePreview() {
            this.openPreview = false;
        },
        handleDeleteMeta(row) {
            this.$confirm(
                '确定删除序号为"' + row.index + '"的数据列?',
                "警告",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                }
            )
                .then(() => {
                    const metas = [];
                    let index = 1;
                    for (let i = 0; i < this.form.metas.length; i++) {
                        const m = this.form.metas[i];
                        if (m.index != row.index) {
                            m.index = index++;
                            m.sort = m.index;
                            metas.push(m);
                        }
                    }
                    this.form.metas = metas;
                })
                .catch(function () {});
        },
        addParam() {
            this.form.params.push({
                index: this.form.params.length + 1,
                name: "param" + (this.form.params.length + 1),
                default_value: "",
                sort: 0,
            });
        },
        handleDeleteParam(row) {
            this.$confirm('确定删除序号为"' + row.index + '"的参数?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const params = [];
                    let index = 1;
                    for (let i = 0; i < this.form.params.length; i++) {
                        const p = this.form.params[i];
                        if (p.index != row.index) {
                            p.index = index++;
                            p.sort = p.index;
                            params.push(p);
                        }
                    }
                    this.form.params = params;
                })
                .catch(function () {});
        },
    },
    watch: {
        open(val) {
            if (val) {
                this.rowDropParams();
            } else {
                if (this.sortableParams) {
                    this.sortableParams.destroy();
                    this.sortableParams = null;
                }
                if (this.sortableMetas) {
                    this.sortableMetas.destroy();
                    this.sortableMetas = null;
                }
            }
        },
        activeTab(val) {
            if (val == "meta") {
                this.rowDropMetas();
            } else {
                if (this.sortableMetas) {
                    this.sortableMetas.destroy();
                    this.sortableMetas = null;
                }
            }
        },
    },
};
</script>

<style scoped>
.sortable-row-demo .drag-btn {
    cursor: move;
    font-size: 12px;
}
.sortable-row-demo .vxe-body--row.sortable-ghost,
.sortable-row-demo .vxe-body--row.sortable-chosen {
    background-color: #dfecfb;
}
</style>
