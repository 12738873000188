<template>
  <div class="container">
    <el-container class="container-wrap">
      <el-header height="0px"></el-header>
      <el-container>
        <el-aside width="300px" style="padding: 0px">
          <el-tabs value="first" :stretch="true">
            <el-tab-pane label="数据列表" name="first"> 
              <model-tree 
                mode="1"
                :data="dataTree"
                :height="height - 40"
                @refresh="getList"
                @add-model="handleAddModel"
                @rename="handleRename"
                @delete="handleDelete"
                @node-click="handleTreeNodeClick"
              />
            </el-tab-pane>
          </el-tabs>
        </el-aside>
        <el-main style="padding: 0px; display: flex; flex-direction: column;">
          <div class="main-header"></div>
          <div class="main-body">
            <model-detail v-if="selectedData"
            v-on="$listeners"
              :data="selectedData"
              :height="height - 40"
              @edit-model="handleEditModel"
              @refresh-model="handleRefreshModel"
            />
          </div>
        </el-main>
      </el-container>
    </el-container>

    <add-sql-model ref="addSqlModel" @change="handleModelChange" />
    <add-api-model ref="addApiModel" @change="handleModelChange" />
    <add-excel-model ref="addExcelModel" @change="handleModelChange" />
  </div>
</template>

<script>
import { listModel, getModel, addModel, updateModel, delModel, previewModel, execModel } from "@/api/core/data/datasource/model"
import AddSqlModelComponent from './add-sql'
import AddApiModelComponent from './add-api'
import AddExcelModelComponent from "./add-excel";
import ModelTreeComponent from './tree'
import ModelDetailComponent from './detail'

export default {
  name: "datasource-model-extractlist",
  components: { 
    'add-sql-model': AddSqlModelComponent,
    'add-api-model': AddApiModelComponent,
    "add-excel-model": AddExcelModelComponent,
    'model-tree': ModelTreeComponent,
    'model-detail': ModelDetailComponent
  },
  props: {
        openfrom: {
            type: String,
            default: "",//formdesign 来自表单设计
        },
    },
  data() {
    return {
      dataList: [],
      dataTree: [],
      databaseTree: [],
      selectedData: undefined,
      height: 0
    }
  },

  created() {
    this.getList()
  },

  mounted() {
    window.addEventListener("resize", this.onWindowResize);
    this.onWindowResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },

  methods: {
    onWindowResize() {
      this.height = document.documentElement.clientHeight - 115 - 40;
      document.getElementsByClassName("container")[0].style.height = this.height + "px";
    },

    getList() {
      listModel({
        mode: '1'
      }).then(response => {
        if (response.code == 0) {
          this.dataList = response.data.list
          this.databaseTree = response.data.databaseTree
          /*const categorys = []
          for (let i = 0; i < this.dataList.length; i++) {
            const d = this.dataList[i]
            if (d.type == '1' || d.type == '2') {
              categorys.push(d)
            }
          }*/
          this.dataTree = this.handleTree(this.dataList, 'id', 'pid')
        }
      })
    },

    handleTreeNodeClick(e) {
      if (e.type != '3' && e.type != '4' && e.type != '5') {
        return
      }
      let extObj = {}
      if (e.ext) {
        extObj = JSON.parse(e.ext)
      }
      this.selectedData = {
        ...e,
        extObj
      }
      this.selectedData.openfrom = this.openfrom
      if (this.databaseTree) {
        for (let i = 0; i < this.databaseTree.length; i++) {
          const node = this.databaseTree[i]
          if (node.children) {
            for (let j = 0; j < node.children.length; j++) {
              const d = node.children[j]
              if (d.id == this.selectedData.database_id) {
                this.selectedData.database_name = d.name
              }
              if (d.id == 0) {
                this.selectedData.local_database_name = d.name
              }
            }
          }
        }
      }
    },

    handleAddModel(mode, pid, type) {
      if (type == '3') {
        this.$refs.addSqlModel.add(mode, pid)
      } else if (type == '4') {
        this.$refs.addApiModel.add(mode, pid)
      } else if (type == "5") {
        this.$refs.addExcelModel.add(mode, pid);
      }
    },

    handleEditModel() {
      if (!this.selectedData) {
        return
      }
      if (this.selectedData.type == '3') {
        this.$refs.addSqlModel.update(this.selectedData.id)
      } else if (this.selectedData.type == '4') {
        this.$refs.addApiModel.update(this.selectedData.id)
      } else if (this.selectedData.type == '5') {
        this.$refs.addExcelModel.update(this.selectedData.id)
      }
    },

    handleRefreshModel(data) {
      let change = false
      for (let i = 0; i < this.dataList.length; i++) {
        const d = this.dataList[i]
        if (data.id == d.id) {
          for (let key in data) {
            if (d[key] != data[key]) {
              d[key] = data[key]
              if (key != 'metas' && key != 'params' && key != 'database_name' && key != 'local_database_name') {
                change = true
              }
            }
          }
          break
        }
      }
      if (change) {
        this.dataTree = this.handleTree(this.dataList, 'id', 'pid')
      }
    },

    handleModelChange(e) {
      this.getList()
      this.handleTreeNodeClick(e)
    },

    handleRename(data) {
      if (this.selectedData && this.selectedData.id == data.id) {
        this.selectedData.name = data.name
      }
    },

    handleDelete(data) {
      if (this.selectedData) {
        if (this.selectedData.id == data.id) {
          this.selectedData = undefined
        } else if (data.children) {
          for (let i = 0; i < data.children.length; i++) {
            if (data.children[i].id == this.selectedData.id) {
              this.selectedData = undefined
              break
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.container {
  background-color: #f7f8fa;
}
.container-wrap {
  height: 100%;
}
.el-header {
  background-color: #fff;
}
.el-aside {
  height: 100%;
  background-color: #fff;
}
.main-header {
  height: 40px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0px 15px;
}
.main-body {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
}
</style>